<template>
  <div class="page-container">
    <page-header></page-header>
    <page-table ref="table" show-paginate :get-data="orderPage" :fields="fields" :search-model="searchModel"
                show-serial-no :item-actions="actions" @onActionClick="handleItemActionClick">
      <template slot="search-items">
        <el-form-item label="文件名称" prop="name">
          <el-input size="small" v-model="searchModel.name" placeholder="文件名称"></el-input>
        </el-form-item>
      </template>
      <template slot="page-actions">
        <div style="margin-bottom: 18px;">
          <el-button icon="el-icon-plus" @click="dialogVisible = true" size="small" type="primary">新增</el-button>
        </div>
      </template>
    </page-table>
    <el-dialog :visible.sync="dialogVisible" width="480px">
      <template slot="title">
        <div class="check-in-records-title">新增文件</div>
      </template>
      <div>
        <el-form :model="pageForm" :rules="pageRules" label-width="120px"
                 ref="pageFormRef" inline label-position="top">
          <el-form-item label="文件名称" required prop="name" style="width: 100%;">
            <el-input placeholder="文件名称" v-model="pageForm.name" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item style="width: 100%;" label="开发商名称" prop="developersId">
            <el-select style="width: 100%;" v-model="pageForm.developersId" filterable clearable
                       placeholder="开发商名称">
              <el-option v-for="item in allDevelopers" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="file" required label="合同文件">
            <el-upload class="upload-demo"
                       action=""
                       accept="application/pdf"
                       ref="filesUpload"
                       list-type="picture"
                       :multiple="true"
                       :show-file-list="false"
                       :limit="5"
                       :file-list="fileList"
                       :http-request="handlerUpload">
              <el-button size="small" style="border: none;background-color: #E6EBF0;">
                <custom-show-icon custom-image custom-image-resource="upload"></custom-show-icon>
                <span style="color: #18191A; margin-left: 10px;position: relative;top: -2.5px;">上传文件</span>
              </el-button>
            </el-upload>
            <div class="common-file-list-container" v-if="currFileName">
              <div class="common-file-list-item-left">
                <div style="display: flex;flex: 1;">
                  <div>
                    <custom-show-icon :filename="currFileName"></custom-show-icon>
                  </div>
                  <div style="margin-left: 10px;margin-right: 10px;flex: 1;font-size: 14px;">{{ currFileName }}</div>
                </div>
              </div>
              <div class="common-file-list-item-right">
                <i class="el-icon-delete-solid" @click="removeHandler"></i>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="confirmDispatchOrder">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisibleTempLate" width="480px">
      <template slot="title">
        <div class="check-in-records-title">创建模板</div>
      </template>
      <div>
        <el-form :model="pageFormTempLate" :rules="pageRulesTempLate" label-width="120px"
                 ref="pageFormTemplateRef" inline label-position="top">
          <el-form-item label="模板名称" required prop="templateName" style="width: 100%;">
            <el-input placeholder="模板名称" v-model="pageFormTempLate.templateName" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item style="width: 100%;" label="开发商名称" prop="developersId">
            <el-select style="width: 100%;" v-model="pageFormTempLate.developersId" filterable clearable
                       placeholder="开发商名称">
              <el-option v-for="item in allDevelopers" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remark" style="width: 100%;">
            <el-input placeholder="备注" v-model="pageFormTempLate.remark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisibleTempLate = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitTemplate">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageTable from "@/components/PageTable";
import {statusMapper} from "@/utils/helper";
import {CONTRACT_MANAGEMENT_STATUS, CONTRACT_TYPE} from "@/utils/config";
import {
  createFile, createTemplate,
  developersAll,
  eFilePage,
} from "@/api/apis";
import CustomShowIcon from "@/components/CustomShowIcon.vue";

export default {
  name: "FileList",
  components: {CustomShowIcon, PageTable, PageHeader},
  data() {
    return {
      auth_link: '',
      //
      dialogVisibleTempLate: false,
      pageFormTempLate: {
        templateName: '',
        developersId: '',
        fileId: '',
        remark: '',
      },
      pageRulesTempLate: {
        templateName: [
          {required: true, message: '请输入模板名称'},
        ],
        developersId: [
          {required: true, message: '请选择开发商'},
        ],
      },
      //
      dialogVisible: false,
      pageForm: {
        name: '',
        developersId: '',
        file: '',
      },
      pageRules: {
        name: [
          {required: true, message: '请输入文件名称'},
        ],
        developersId: [
          {required: true, message: '请选择开发商'},
        ],
        file: [
          {required: true, message: '请上传合同文件'},
        ],
      },
      fileList: [],
      currFileName: '',
      currFile: {},

      // -
      searchModel: {
        // 文件名称
        name: '',
      },
      orderPage: eFilePage,
      fields: [
        {label: '文件名称', key: 'name'},
        {
          label: '创建人',
          render({createUser}, h) {
            return h('div', createUser ? createUser.name : '')
          }
        },
        {
          label: '所属组织',
          render({developers}, h) {
            return h('div', developers?.name)
          }
        },
        {label: '创建时间', key: 'time'},
      ],
      actions: [
        {
          action: 'create',
          label: '创建模板',
          type: 'primary',
        },
      ],
      stateMap: CONTRACT_MANAGEMENT_STATUS,
      CONTRACT_TYPE,
      //
      allDevelopers: [],
      selectedItem: {},
    }
  },
  created() {

  },
  mounted() {
    developersAll().then(res => {
      this.allDevelopers = res
    })
  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    statusMapper,
    toEdit() {
      window.open(this.auth_link, '_blank')
    },
    /**
     * 移除文件
     */
    removeHandler() {
      this.fileList = []
      this.currFileName = ''
      this.currFile = ''
      this.pageForm.file = this.currFile
    },
    /**
     * 自定义文件上传
     */
    handlerUpload(e) {
      const sizeMB = e.file.size / 1024 / 1024
      if (sizeMB > 50) {
        this.$message.warning('不可大于50M')
        return
      }

      this.currFileName = e.file.name
      this.currFile = e.file
      this.pageForm.file = this.currFile
    },
    /**
     * 上传文件
     */
    confirmDispatchOrder() {
      this.$refs.pageFormRef.validate((valid) => {
        if (!valid) {
          return false
        }

        const formData = new FormData()
        formData.append('file', this.currFile)
        createFile(this.pageForm.name, this.pageForm.developersId, formData).then(() => {
          this.dialogVisible = false
          this.$message.success('提交成功')
          this.$refs.table.loadData()
        })
      })
    },
    /**
     * 根据文件创建模板
     */
    createAction(item) {
      this.dialogVisibleTempLate = true
      this.selectedItem = item
    },
    submitTemplate() {
      this.$refs.pageFormTemplateRef.validate((valid) => {
        if (!valid) {
          return false
        }

        this.pageFormTempLate.fileId = this.selectedItem.id

        createTemplate(this.pageFormTempLate).then((res) => {
          this.dialogVisibleTempLate = false
          this.$message.success('提交成功')
          //
          this.auth_link = res
          this.toEdit()
        })
      })
    },
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
  },
}
</script>

<style lang="scss" scoped>
.common-file-list-container {
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  .common-file-list-item-left {
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #F5F7FA;
    border-radius: 2px;
    font-size: 16px;
  }

  .common-file-list-item-right {
    margin-left: 14px;
    font-size: 16px;
    color: #8D9094;
  }
}
</style>